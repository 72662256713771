<template>
    <div class="block-footer-container" :class="[device_platform]">
        <div class="footer-text">
            <!-- <p>
                *Выгода в размере 640 000 рублей указана для автомобилей «Geely Tugella (все комплектации) 2023 года выпуска, и состоит из специальной выгоды в размере 150 000 руб., выгоды при покупке в кредит в размере 160 000 рублей и выгоды по программе «Трейд-ин» в размере 290 000 рублей. Выгода 330 000 рублей на указанные автомобили предоставляется при сдаче в Трейд-ин автомобиля «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»). Программа действует с 01.07.2024 по 31.07.2024 г.
            </p> -->
            Максимальная выгода 
              <b>
                до 600 000 рублей на Atlas Pro (специальная выгода + выгода в трейд-ин (лояльный/специальный) + выгода в кредит)
              </b>
              <br>Выгода в размере 600 000 рублей указана для автомобилей «Geely Atlas Pro» (все комплектации, кроме Comfort 2WD и Luxury 2WD) и состоит из выгоды в размере 110 000 рублей, применяемой к цене автомобиля, выгоды по программе «Трейд-ин» в размере 270 000 рублей и выгоды при покупке с использованием кредитных средств Банка в размере 220 000 рублей . 
              <br>
              <br>Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely». Единовременная скидка 270 000 рублей на указанные выше автомобили предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля марки «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).
              <br>
              <br>При приобретении конечным покупателем Автомобиля с использованием кредитных средств Банка, по условиям Программы, Дилер снижает розничную цену нового Автомобиля для конечного покупателя на сумму не менее 220 000 рублей. Выгода по Программе предоставляется при покупке нового автомобиля «Geely» в кредит в любом банке. 
              <br>
              <br>Реклама. ООО «Автостиль». Не оферта. Программа действует с 01.10.2024 г. по 31.10.2024 г. Количество автомобилей ограничено. ООО «ДЖИЛИ-МОТОРС» вправе изменить сроки и условия Программы. Подробные условия и наличие автомобилей уточняйте у менеджеров ДЦ Geely Автостиль (Сайт дилера:
                <a href="https://avtostyl-geely.ru/">avtostyl-geely.ru</a>)
              <br><br>

              <b>
              Гарантия 5 лет
              </b>
              <br>Гарантия распространяется на 5 лет или на 150 000 км. пробега (в зависимости от того, что наступит ранее), за исключением отдельных комплектующих автомобиля. Подробности уточняйте по тел.8 800 200 02 89 (звонок по РФ бесплатный) либо у сотрудников дилерских центров «Geely».

            <br><br><a href="../documents/rules.pdf" target="_blank_" class="footer__link">Правила пользования сайтом</a>
            <br><br><a href="../documents/requisites.pdf" target="_blank" class="footer__link">Реквизиты</a>

            <br><br>ООО «Автостиль»
            <br>Юридический адрес: 198152, город Санкт-Петербург, Краснопутиловская ул., д. 65 литер а, помещ. 5-н №3
            <br>ОГРН: 1197847129468
            <br>ИНН: 7805752165
            <br>© {{ currentYear }} «АВТОСТИЛЬ GEELY» - официальный дилер Geely.
            <div class="hidden-phone">
                8 (495) 135-40-71
            </div>
        </div>
    </div>
</template>

<script>
    import Mixin from '../common/mixin';

    export default {
        name: "block-footer",
        mixins: [Mixin],
        data() {
            return {
              currentYear: '',
            }
        },
        mounted() {
          this.currentYear = new Date().getFullYear()
        }
    }
</script>

<style scoped lang="scss">
    @import "../styles/constants.scss";

    .mobile {
        &.block-footer-container {
            .footer-text {
                padding: 30px 10px 40px;
            }
        }
    }

    .block-footer-container {
        width: 100%;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #efefef;
        .footer-text {
            width: 100%;
            padding: 30px 50px 40px;
            margin: 0 auto;
            text-align: center;
            font-weight: 400;
            font-size: 14px;
            color: black;
        }

        .footer__link {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .hidden-phone {
        position: absolute;
        z-index: -100;
    }
</style>
