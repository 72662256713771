<template>
    <form class="callback-form CKiForm" @submit.prevent="submitThis" :class="[device_platform, {'horizontal': horizontal}, form_class]">
        <div class="input-block">
            <input name=”name”
                   type="text"
                   placeholder="Ваше имя"
                   :title="'Введите Ваше имя'"
                   v-model="name"
                   ref="name"
                   v-if="data.form === 'coolray'"
                   required>
            <input name=”tel”
                   type="tel"
                   placeholder="Ваш телефон"
                   :title="'Введите номер телефона'"
                   v-model="phone"
                   ref="phone"
                   v-mask
                   :pattern="'.*[0-9]{1}.*[0-9]{3}.*[0-9]{3}.*[0-9]{4}'"
                   required>
            <button type="submit"
                    :disabled="!acceptance"
                    class="btn-blue CKFormTrigger"
                    :class="[
                        {'disabled': !acceptance},
                        `${btn_class}`
                    ]"
                    ref="submitter">
                <slot></slot>
            </button>
        </div>
        <div class="agreement"
             v-if="CONSTANTS.need_agreement">
            <checkbox v-model="acceptance"></checkbox>
            <div class="agreement-confirm">Даю согласие на <a href="../../documents/personal-info.pdf" target="_blank" class="agreement__link">обработку моих персональных данных</a></div>
        </div>
    </form>
</template>

<script>
    import Mixin from '@/common/mixin';
    import Mask from '@/common/mask';
    import Checkbox from '../checkbox';

    export default {
        name: "callback-input",
        components: {Checkbox},
        directives: {Mask},
        mixins: [Mixin],
        props: ['horizontal', 'data', 'form_class', 'btn_class'],
        data() {
            return {
                acceptance: true,
                phone: '',
                name: '',
            }
        },
        watch: {
            name: function() {
              if (this.name.trim().length > 3) {
                  this.$refs.name.setCustomValidity('');
              }
          }
        },
        methods: {
            submitThis(event) {
                if (this.$refs.phone.validity.valueMissing || !this.$refs.phone.validity.valid) {
                    this.$refs.phone.setCustomValidity('Введите номер телефона');
                }

                if (!this.$refs.phone.validity.valueMissing && !this.$refs.phone.validity.patternMismatch) {
                    this.$refs.phone.setCustomValidity('');
                }

                if (this.data.form === 'coolray' && this.name.trim().length < 3) {
                    this.$refs.name.setCustomValidity('Введите Ваше имя');
                }

                if (this.data.form === 'coolray') {
                    if (this.acceptance && this.$el.reportValidity()) {
                        if (this.CONSTANTS.cKeeper === 'iform') {
                            ckForms.send(this.$el);
                        }
                        ym(89627801,'reachGoal','sendform')

                        this.$emit('callBack', {
                            phone: this.phone,
                            data: this.data,
                            name: this.name,
                        });
                    }
                } else {
                    if (this.acceptance && this.$el.reportValidity()) {
                        if (this.CONSTANTS.cKeeper === 'iform') {
                            ckForms.send(this.$el);
                        }
                        ym(89627801,'reachGoal','sendform')

                        this.$emit('callBack', {
                            phone: this.phone,
                            data: this.data,
                        });
                    }
                }
            },
            getAgreement() {
                this.$emit('getAgreement');
            }
        }
    }
</script>

<style lang="scss">
    @import "../../styles/constants.scss";

    .tablet {

        .callback-form,
        .callback-form-contacts
         {
            .agreement {

                .agreement-confirm {
                    font-size: 11px;
                    span {

                    }
                }
            }
        }
    }

    .mobile {

    }

    .callback-form,
    .callback-form-contacts {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.horizontal {
            .input-block {
                flex-direction: row;
                justify-content: space-between;

                input, button {
                    width: 48%;
                }
            }
        }

        &:not(.horizontal) {
            .input-block {
                flex-direction: column;

                input, button {
                    width: 100%;
                }

                input {
                    margin-bottom: 10px;
                }
            }
        }

        .input-block {
            width: 100%;
            display: flex;

            input {
                height: 50px;
                border: 1px solid #C6C6C6;
                color: black;
                text-align: center;
                font-size: 16px;
                text-transform: uppercase;
                border-radius: 2px;

                &::placeholder {
                    color: black;
                }
            }
        }

        .agreement {
            display: flex;
            margin-top: 10px;
            align-self: flex-start;
            align-items: center;
            text-shadow: 0 0 3px #fff;
            color: #000;

            .agreement-confirm {
                font-size: 12px;
                a {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

.main-invert {
    .agreement {
        color: white;
        text-shadow: 0 0 3px #C6C6C6;
    }
}

.agreement-invert {
    .agreement {
        color: white;
        text-shadow: 0 0 3px #C6C6C6;
    }
}

.btn-blue {
    &.disabled {
        background-color: #555;

        &:hover {
            background-color: #555;
            color: #fff;
        }
    }
}

.main {
    .input-block {
        input {
            border: 4px solid #1f6fb6;
        }
    }
}

.btn__arrow {
  &_1 {
    position: relative;
    background: transparent;
    width: 100%;
    border: 0;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: url(../../images/icons/btn_bg.png) no-repeat center;
      background-size: 100%;
      z-index: -1;
    }

    &:hover {
      background: $dark-blue;
      color: #fff;
    }
  }

  &_2 {
    position: relative;
    background: $dark-blue;
    width: 100%;
    border: 0;

    &:after {
      content: "";
      position: absolute;
      width: 94px;
      height: 100%;
      top: 0;
      right: 0;
      background: url(../../images/icons/btn_bg_white.svg) no-repeat center;
      background-size: 100%;

      @media (max-width: 400px) {
        right: 0px;
      }
    }

    &:hover {
      background: $blue;
      color: #000;
    }

    &.btn-blue {
        padding-right: 55px;

    }
  }
}

.block-main-container {
    .callback-form.horizontal {
        .input-block {
            gap: 15px;

            input {
                width: 100%;
                max-width: 228px;
                border: 1px solid #000;
            }

            .btn-blue {
                width: 330px;
                justify-content: flex-start;
                padding-left: 13px;
                font-size: 13px;
                background: #343E47;
            }
        }
    }

    .callback-form {
        .input-block {
            .btn-blue {
                background: #343E47;
                padding-left: 0;
                font-size: 12px;
            }
        }
    }
}
</style>
