<template>
  <div class="block-main-container" :class="[device_platform]">
    <div v-if="displayNone" class="callback-wrapper">
      <!-- <block-clock></block-clock> -->

      <callback-input 
        class="callback-input callback-input--main" 
        v-on="$listeners" 
        :horizontal="device_platform !== 'mobile'"
        :form_class="device_platform !== 'mobile' ? '' : ''" 
        :data="{ type: 'sale', form: 'main' }"
        :btn_class="'btn__arrow_2'"
      >
        Зафиксируй предложение
      </callback-input>
    </div>

    <div class="arrow arrow-left" v-if="device_platform !== 'mobile' && array_of_slides.length > 1"></div>
    <div class="arrow arrow-right" v-if="device_platform !== 'mobile' && array_of_slides.length > 1"></div>
    <div class="swiper-container-main">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="i in array_of_slides" :key="i">
          <div class="content-container" :class="'_' + i" v-if="i === 1">
            <div class="header-container">
              <div class="header">
                <span class="highlight">Geely Atlas 1.8 Turbo в ТЦ Кунцево</span>
                <br />Только до 15 января
                <span style="white-space: nowrap">выгода до 120 000₽*!</span>
                <!-- <br>Успейте до подорожания! -->
              </div>

              <callback-input 
                class="callback-input" 
                v-on="$listeners" 
                horizontal="true"
                :form_class="device_platform !== 'mobile' ? 'main-invert' : ''"
                :data="{ type: 'sale', form: 'main' }"
                :btn_class="'btn__arrow_2'"
              >
                Получить предложение
              </callback-input>
            </div>
          </div>
          <div class="content-container" :class="'_' + i" v-if="i === 2">
            <div class="content-container__container container">

              <div class="header-container">
                <div class="header" v-if="!cfo">
                  <!-- <p class="title highlight uppercase">
                    ФЕВРАЛЬСКИЙ SALE
                  </p> -->

                  <p class="label_border uppercase">
                    ТОЛЬКО В {{ dateMonth }}!
                  </p>

                  <p class="subtitle uppercase">
                    Дополнительное оборудование
                  </p>

                  <p class="advanatge uppercase">
                    Выгода до <span>1 000 000</span> ₽
                  </p>
                </div>

                <div class="header" v-else>
                  Рекордные выгоды
                  <span style="white-space: nowrap">до 390 000 ₽</span>
                  <span style="white-space: nowrap">в ТЦ Кунцево!</span>
                  <br />Компенсируем расходы
                  <span style="white-space: nowrap">на дорогу</span>
                  <span style="white-space: nowrap">из регионов</span>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container" :class="'_' + i" v-if="i === 3">
            <div class="content-container__container container">
              <div class="header">
                С заботой о Вашем здоровье. Geely Кунцево.
                <br />Один год телемедицины - в подарок!
                <sup style="font-size: 0.5em">6</sup>
              </div>
              <div class="btn-blue" :id="`btn-common-${i}`">
                Узнайте подробности
              </div>
            </div>
            <block-clock />
          </div>

          <div class="content-container" :class="'_' + i" v-if="i === 5">
            <div class="content-container__container container">
              <div class="header" v-if="!cfo">
                <span class="highlight-white uppercase">НЕ ВРЕМЯ ОТКЛАДЫВАТЬ!</span>
                <br />Geely в наличии с ПТС
              </div>
              <callback-input 
                class="callback-input" 
                v-on="$listeners" 
                :horizontal="device_platform !== 'mobile'"
                :form_class="device_platform !== 'mobile' ? '' : ''" 
                :data="{ type: 'sale', form: 'main' }"
                :btn_class="'btn__arrow_2'"
              >
                Получить предложение
              </callback-input>
            </div>
          </div>

          <div class="content-container" :class="'_' + i" v-if="i === 6">
            <div class="content-container__container container">
              <div class="content-info">
                <h2 class="content-title">Специальное предложение в <strong>АВТОСТИЛЬ</strong></h2>
                <div class="content-subtitle">
                  <span class="content-subtitle--blue"><strong>GEELY</strong> с выгодой до <strong>800 000 ₽</strong></span>
                  <strong>до конца месяца</strong>
                </div>
                <div class="content-gifts">
                  <span class="content-gift">
                    <img src="../images/icons/gift_blue-2.svg" alt="доп.оборудование в подарок">
                    <span>ДОП.ОБОРУДОВАНИЕ <strong>В ПОДАРОК</strong></span>
                  </span>
                </div>

                <div
                  v-if="device_platform !== 'mobile'" 
                  class="content-fos"
                >
                  <callback-input 
                    class="callback-input" 
                    v-on="$listeners" 
                    :horizontal="device_platform !== 'mobile'"
                    :form_class="device_platform !== 'mobile' ? '' : ''" 
                    :data="{ type: 'sale', form: 'main' }"
                    :btn_class="'btn__arrow_2'"
                  >
                    Зафиксируй предложение
                  </callback-input>
                </div>
              </div>

              <!-- <div class="content-label">
                <div class="content-label--img">
                  <img src="../images/icons/list.svg" alt="">
                </div>
                <div class="content-label--text">
                  <p><b>Успейте до повышения утилизационного сбора</b></p>
                  <p><span><strong>+15%</strong> к стоимости автомобилей</span></p>
                </div>
              </div> -->
            </div>
          </div>
          
          <div class="content-container" :class="'_' + i" v-if="i === 7">
            <div class="content-container__container container">
              <div class="content-info">
                <h2 class="content-title">НОВАЯ МОДЕЛЬ <strong>GEELY CITYRAY</strong> уже в наличии!</h2>
                <div class="content-subtitle">
                  <span class="content-subtitle--blue"><strong>ОСОБОЕ ПРЕДЛОЖЕНИЕ</strong></span>
                  <strong>в АВТОСТИЛЬ</strong>
                </div>
                <div class="content-gifts">
                  <span class="content-gift">
                    <img src="../images/icons/gift_blue-2.svg" alt="доп.оборудование в подарок">
                    <span>ДОП.ОБОРУДОВАНИЕ <strong>В ПОДАРОК</strong></span>
                  </span>
                </div>

                <div
                  v-if="device_platform !== 'mobile'" 
                  class="content-fos"
                >
                  <callback-input 
                    class="callback-input" 
                    v-on="$listeners" 
                    :horizontal="device_platform !== 'mobile'"
                    :form_class="device_platform !== 'mobile' ? '' : ''" 
                    :data="{ type: 'sale', form: 'main' }"
                    :btn_class="'btn__arrow_2'"
                  >
                    Зафиксируй предложение
                  </callback-input>
                </div>
              </div>

              <!-- <div class="content-label">
                <div class="content-label--img">
                  <img src="../images/icons/list.svg" alt="">
                </div>
                <div class="content-label--text">
                  <p><b>Успейте до повышения утилизационного сбора</b></p>
                  <p><span><strong>+15%</strong> к стоимости автомобилей</span></p>
                </div>
              </div> -->
            </div>
          </div>

          <div class="content-container" :class="'_' + i" v-if="i === 8">
            <div class="content-container__container container">
              <div class="content-info">
                <h2 class="content-title">НОВАЯ МОДЕЛЬ <strong>GEELY PREFACE</strong> уже в наличии!</h2>
                <div class="content-subtitle">
                  <span class="content-subtitle--blue"><strong>ОСОБОЕ ПРЕДЛОЖЕНИЕ</strong></span>
                  <strong>в АВТОСТИЛЬ</strong>
                </div>
                <div class="content-gifts">
                  <span class="content-gift">
                    <img src="../images/icons/gift_blue-2.svg" alt="доп.оборудование в подарок">
                    <span>ДОП.ОБОРУДОВАНИЕ <strong>В ПОДАРОК</strong></span>
                  </span>
                </div>

                <div
                  v-if="device_platform !== 'mobile'" 
                  class="content-fos"
                >
                  <callback-input 
                    class="callback-input" 
                    v-on="$listeners" 
                    :horizontal="device_platform !== 'mobile'"
                    :form_class="device_platform !== 'mobile' ? '' : ''" 
                    :data="{ type: 'sale', form: 'main' }"
                    :btn_class="'btn__arrow_2'"
                  >
                    Зафиксируй предложение
                  </callback-input>
                </div>
              </div>
<!-- 
              <div class="content-label">
                <div class="content-label--img">
                  <img src="../images/icons/list.svg" alt="">
                </div>
                <div class="content-label--text">
                  <p><b>Успейте до повышения утилизационного сбора</b></p>
                  <p><span><strong>+15%</strong> к стоимости автомобилей</span></p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div v-if="array_of_slides.length > 1" class="pagination"></div>
    </div>

    <div 
      v-if="device_platform === 'mobile'"
      class="block-main-fos"
    >
      <callback-input 
        class="callback-input" 
        v-on="$listeners" 
        :horizontal="device_platform !== 'mobile'"
        :form_class="device_platform !== 'mobile' ? '' : ''" 
        :data="{ type: 'sale', form: 'main' }"
        :btn_class="'btn__arrow_2'"
      >
        Получить предложение
      </callback-input>
    </div>
  </div>
</template>

<script>
import Mixin from "../common/mixin";
import finance from "../common/finance";
import CallbackInput from "./callback-form/callback-input";
import CF from "../common/common-functions";
import BlockClock from './block-clock-megatimer';
import Swiper from "swiper";
import $ from "jquery";
import Inputmask from "inputmask";

export default {
  name: "block-main",
  components: { CallbackInput, BlockClock },
  mixins: [Mixin, finance],
  data() {
    /*if(dateMonth[dateMonth.length-1] == 'т'){
      dateMonth= dateMonth + 'е'

    }else{
      dateMonth[dateMonth.length-1]='е'
    }
*/
    return {
      swiper: null,
      date: CF.getDateString(),
      array_of_slides: [6, 7, 8],
      cfo: false,
      displayNone: false
    };


  },
  setup(){
    let today = new Date();
    let dateMonth = today.toLocaleString("ru", { month: 'long' });

    if(dateMonth.slice(-1) == 'т'){
      dateMonth = dateMonth + 'е'
    }else{
     dateMonth =  dateMonth.slice(0, -1) +'е'
    }

    return{
      dateMonth: dateMonth,
    }
  },
  created() {
    function getURLParameter(name) {
      var url = window.location.href;
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var results = new RegExp("[?&]" + name + "=?([^&#]*)").exec(
        url || window.location.href
      );
      console.log(results);
      return results == null ? null : results[1] || true;
    }
    if (getURLParameter("region=cfo")) {
      this.cfo = true;
    }
  },
  mounted() {
    this.initSwiper();
    window.addEventListener("click", (event) => {
      if (!event.target || (!event.target.id && !event.target.classList)) {
        return;
      }
      if (
        event.target.id === "btn-common-1" ||
        event.target.id === "btn-common-2" ||
        event.target.id === "btn-common-3"
      ) {
        this.getCall({ type: "sale", form: "main" });
      }
    });
  },
  watch: {
    device_platform() {
      if (this.swiper) {
        this.swiper.destroy();
        this.swiper = null;
        this.$nextTick(this.initSwiper);
      }
    },
  },
  methods: {
    getAgreement() {
      this.$emit("getAgreement");
    },
    initSwiper() {
      if (this.swiper) return;
      this.swiper = new Swiper(".swiper-container-main", {
        loop: this.array_of_slides.length > 1,
        autoHeight: false,
        simulateTouch: false,
        autoplay:
          this.array_of_slides.length > 1
            ? {
              delay: 15000,
            }
            : false,
        navigation: {
          nextEl: ".arrow-right",
          prevEl: ".arrow-left",
        },
        pagination: {
          el: '.pagination',
          clickable: true
        }
      });

      if (this.swiper.loopedSlides) {
        //повесить маску на input в дубликате
        let dublicate = $(".swiper-slide-duplicate"),
          dublicate_inputs = dublicate.find("input[type=tel]");

        dublicate_inputs.each((index) => {
          let el = dublicate_inputs.get(index),
            elem = $(el);

          Inputmask({ mask: "(+7|8) (999) 999-9999" }).mask(el);
          elem
            .on("focus", () => {
              if (!el.value.length) {
                setTimeout(() => {
                  el.setSelectionRange(4, 4);
                }, 0);
              }
            })
            .on("blur", () => {
              if (el.value.length < 5) {
                el.value = "";
              }
            });
        });

        //перевесить megatimer на баннер, если он есть. В нижнем коде учитывается, что номер первого слайда - 1,
        //а блок с мегатаймером имеет id timer_block

        setTimeout(() => {
          let dublicate = $('.swiper-slide-duplicate'),
            dublicate_content = dublicate.find('.content-container._1'),
            dublicate_bclock = dublicate_content.find('.block-clock-container').find('.bclock-clock'),
            clock = $('#timer_block');

          dublicate_bclock.empty();
          dublicate_bclock.get(0).appendChild(clock.clone().get(0));
        }, 500);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.mobile-banner {
  display: none;
}

.gift {
  display: flex;
  align-items: center;
  font-size: 32px;
  line-height: 150%;
  margin-top: 15px;

  &::before {
    background: url(../images/icons/gift_white.svg) no-repeat 50%/contain;
    content: "";
    height: 40px;
    width: 40px;
    margin-right: 30px;
  }
}

.tires {
  display: flex;
  align-items: center;
  font-size: 32px;
  line-height: 150%;
  margin-top: 10px;

  &::before {
    background: url(../images/icons/tires_white.svg) no-repeat 50%/contain;
    content: "";
    height: 40px;
    width: 40px;
    margin-right: 30px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.desktop {

  ._4,
  ._5 {
    .highlight {
      text-shadow: 0px 0px 20px #000;
    }
  }
}

.mobile {
  // &.block-main-container {
  //   padding-bottom: 330px;
  // }

  // .mobile-banner {
  //   display: block;
  // }

  .header {
    padding: 0;
    margin-right: 0;
  }

  .subtitle {
    margin-bottom: 10px;
    font-size: 18px;
  }

  .content-title {
    font-size: 21px;
  }

  .content-subtitle {
    font-size: 18px;
    flex-direction: column;
    gap: 3px;
  }

  .content-label {
    padding: 3px 10px 3px 3px;
    gap: 7px;

    &--img {
      img {
        width: 30px;
      }
    }

    &--text {
      b {
        font-size: 10px;
      }

      span {
        font-size: 14px;

        strong {
          font-size: 16px;
        }
      }
    }
  }

  .content-gifts {
    margin-top: 20px;
  }

  .content-gift {
    font-size: 15px;
  }

  .content-container {
    min-height: unset;

    &__container {
      padding: 30px 12px;
      align-items: stretch;
    }

    &._1 {
      height: calc(100vw * 700 / 800 + 250px);
      background-image: url("../images/background/atlas_800x700.jpg");
      background-size: 100%;
      background-position: top;

      .header {
        background: none;
      }
    }

    &._2 {

      .header {
        color: #fff;

        // .gift {
        //   font-size: 14px;
        //   margin: 0;

        //   &::before {
        //     width: 23px;
        //     height: 23px;
        //     margin-right: 9px;
        //   }
        // }

        // .tires {
        //   font-size: 14px;
        //   margin-top: 5px;

        //   &::before {
        //     width: 23px;
        //     height: 23px;
        //     margin-right: 9px;
        //   }
        // }

        .title {
          font-size: 26px;
          margin-bottom: 15px;
        }

        .label {
          font-size: 16px;
          margin-bottom: 20px;

          &_border {
            font-size: 29px;
            line-height: 1;
          }
        }

        .subtitle {
          font-size: 16px;
        }

        .advanatge {
          font-size: 19px;
          padding: 14px 26px;
          margin-top: 15px;
            line-height: 1;

          span {
            font-size: 26px;
            line-height: 1;
          }
        }
      }
    }

    &._3 {
      background-position: top right;
      background-size: 130%;
      background-position: top;

      .header {
        color: #000;
      }
    }

    &._4 {
      height: calc(100vw * 700 / 800 + 320px);
      background-image: url("../images/background/sales_geely_atlas_800_700.jpg");
      background-size: 100%;
      background-position: top;

      .header {
        background: none;
      }
    }

    &._5 {
      height: calc(100vw * 700 / 800 + 220px);
      background-image: url("../images/background/no_time_to_postpone_800_700.jpg");
      background-size: 100%;
      background-position: top;

      .header {
        background: none;
      }
    }

    &._6 {
      height: calc(100vw * 1048 / 640);
      background-image: url("../images/background/banner-6_m.jpg");
      background-size: 100%;
      background-position: top;
    }

    &._7 {
      height: calc(100vw * 1048 / 640);
      background-image: url("../images/background/banner-7_m.jpg");
      background-size: 100%;
      background-position: top;
    }

    &._8 {
      height: calc(100vw * 1048 / 640);
      background-image: url("../images/background/banner-8_m.jpg");
      background-size: 100%;
      background-position: top;
    }
  }

  .header-container {
    height: auto;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .header {
    color: #000;
    font-size: 20px;
    line-height: 30px;
    background: transparent;
  }


  .callback-input {
    margin-top: 0;
    width: 300px;
  }

  .highlight {
    margin-bottom: 7px;
    line-height: 1.1;
    font-size: 30px;
  }
}

.tablet {

  ._1,
  ._4,
  ._5 {
    .highlight {
      text-shadow: 0px 0px 20px #000;
    }
  }

  .header {
    font-size: 36px;
    line-height: 42px;
    padding-top: 30px;
  }

  .subtitle {
    font-size: 28px;
  }

  .content-container {
    margin-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    height: 600px;
    min-height: unset;

    &._1 {
      background-position-x: 71%;
    }

    &._2 {

      .gift {
        font-size: 23px;

        &::before {
          width: 39px;
          height: 39px;
          margin-right: 15px;
        }
      }

      .tires {
        font-size: 23px;

        &::before {
          width: 39px;
          height: 39px;
          margin-right: 15px;
        }
      }
    }

    &._3 {
      .header {
        max-width: 90%;
        text-shadow: 1px 2px 3px #fff;
      }
    }
  }

}

.block-main-container {
  width: 100%;
  position: relative;
  padding-bottom: 60px;
}

.swiper-container-main {
  width: 100%;
  position: relative;
}

.highlight {
  font-weight: bold;
}

.highlight-black {
  color: #000;
  font-weight: bold;
}

.highlight-white {
  color: #fff;
  font-weight: bold;

  @media (max-width: 800px) {
    color: #000;
  }
}

.subtitle {
  font-size: 40px;
  line-height: 1.1;
}

.content-info {
  padding: 20px;
  width: 100%;
  max-width: 1320px;
  border-radius: 20px;
  background-color: #FFFFFF80;
  text-align: center;
}

.content-label {
  display: flex;
  align-items: center;
  background-color: #FFF;
  padding: 6px 27px 6px 6px;
  border-radius: 70px;
  gap: 13px;

  &--img {
    img {
      display: block;
    }
  }

  &--text {
    b {
      text-transform: uppercase;
      font-size: 16px;
    }

    span {
      font-size: 20px;

      strong {
        font-size: 22px;
        color: #002D96;
      }
    }
  }
}

.content-title {
  font-size: 45px;
  font-weight: 600;
}

.content-subtitle {
  font-size: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;

  &--blue {
    padding: 10px;
    background-color: #002D96;
    border-radius: 100px;
    color: #fff;
  }
}

.content-gifts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}

.content-gift {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 22px;
  font-weight: 600;

  strong {
    color: #002D96;
  }
}

.content-fos {
  width: max-content;
  margin: 30px auto 0;
}

.content-container {
  position: relative;
  height: 828px;
  // min-height: 570px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 25px;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 20px;
    align-items: flex-end;
  }

  &._1 {
    background: url("../images/background/bg-atlas.jpg") no-repeat center;
    background-size: cover;
    background-position-x: right;

    .header {
      background: rgba(0, 0, 0, 0.7);
      padding: 10px 20px;
      margin-top: 15px;
      max-width: fit-content;
    }
  }

  &._2 {
    background:  linear-gradient(0deg,rgba(176, 183, 192, 0.95) 13%,rgba(255, 255, 255, 0.0) 30.65%), url("../images/background/september__banner.jpg") no-repeat center bottom;
    background-size: cover;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        text-align: center;
        line-height: 110%;
        font-size: 52px;

        margin-bottom: 15px;
      }
      
      .label {
        text-align: center;
        border-radius: 41.5px;
        padding: 0 22px;
        font-size: 21px;
        line-height: 200%;
        background: #FFF;
        width: max-content;
        color: #343F47;
        margin-bottom: 30px;

        &_border {
          font-size: 64px;
          font-style: normal;
          font-weight: 700;
          line-height: 62.476px;
          background: transparent;
          border-radius: 0px;
          color:#B3141A;
          background: #fff;
          border: 4px solid #B3141A;
          padding: 10px;
          margin-bottom: 15px;
        }
      }

      .subtitle {
        font-size: 27px;
        text-align: center;
        color: #000;
      }

      .advanatge {
        border-radius: 89.187px;
        border: 4px solid #FFF;
        background: #B3141A;
        backdrop-filter: blur(4px);
        font-size: 46px;
        font-style: normal;
        font-weight: 700;
        line-height: 57.2px;
        color: #FFF;
        padding: 17px 32px;
        margin-top: 20px;

        span {
          font-size: 72px;
        }
      }
    }
  }

  &._3 {
    background: url("../images/background/bg-march-2.jpg") no-repeat center right;

    .header {
      color: #fff;
    }
  }

  &._4 {
    background: url("../images/background/sales_geely_atlas_1920_650.jpg") no-repeat center;
    background-size: cover;

    .header {
      background: rgba(0, 0, 0, 0.7);
      padding: 10px 20px;
      margin-top: 15px;
      max-width: fit-content;
    }
  }

  &._5 {
    background: url("../images/background/no_time_to_postpone_1920_650.jpg") no-repeat center;
    background-size: cover;

    .header {
      background: rgba(0, 0, 0, 0.7);
      padding: 10px 20px;
      margin-top: 15px;
      max-width: fit-content;
    }
  }

  &._6 {
    background:  linear-gradient(0deg,rgba(176, 183, 192, 0.95) 13%,rgba(255, 255, 255, 0.0) 30.65%), url("../images/background/banner-6.jpg") no-repeat center bottom;
    background-size: cover;
  }

  &._7 {
    background: url("../images/background/banner-7.jpg") no-repeat center bottom;
    background-size: cover;
  }

  &._8 {
    background:  linear-gradient(0deg,rgba(176, 183, 192, 0.95) 13%,rgba(255, 255, 255, 0.0) 30.65%), url("../images/background/banner-8.jpg") no-repeat center bottom;
    background-size: cover;
  }
}

.header-container {
  display: flex;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.header {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  line-height: 70px;
  padding-top: 60px;
  width: 100%;
  text-align: center;
}

.callback-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 85px;
  display: flex;
  justify-content: space-around;
  width: max-content;
  margin: 0 auto;
  align-items: center;
}

.header-white {
  color: #fff;
}

.btn-blue {
  border: none;
}

.arrow {
  position: absolute;
  top: 50%;
  width: 49px;
  height: 80px;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
  outline: none;

  &:active {
    opacity: 0.5;
  }
}

.arrow-left {
  background: url("../images/utils/arrow-left.png") no-repeat center;
  left: 20px;
}

.arrow-right {
  background: url("../images/utils/arrow-right.png") no-repeat center;
  right: 20px;
}

.pagination {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -10px;
  z-index: 1;

  :deep(.swiper-pagination-bullet) {
    width: 157px;
    height: 4px;
    background: #FFFFFF;
    opacity: .5;
    border-radius: 100px;
  }

  :deep(.swiper-pagination-bullet-active) {
    opacity: 1;
  }
}

.input-container {
  width: 320px;
  background: #fff;
  padding: 20px 20px;
}

.callback-input {
  z-index: 20;
  &--main {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}

.block-main-fos {
  margin: 18px auto 0;
  width: max-content;
}

@media screen and (max-width: 1440px) {
  .desktop {
    .content-container {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

@media (max-width: 1200px) and (min-width: 800px) {
  .block-main-container {
    margin: 20px 0 0 0;
  }

  .block-clock-container {
    max-width: 370px;
  }
}

@media (max-width: 800px) {
  .block-main-container {
    padding-bottom: 40px;
  }
  .content-container {
    height: 588px;
    margin-top: 0;
  }
  
  .content-container._2 {
    background: url('../images/background/september__banner_m.jpg') no-repeat center;
    background-size: cover;
    background-position: 30%;
  }

  .callback-wrapper {
    flex-direction: column;
    bottom: 50px;
  }

  .callback-input--main {
    padding-top: 0;
  }
}

@media (max-width: 480px) {
  .callback-wrapper {
    width: 100%;

    .callback-input {
      margin: 0 auto !important;
    }
  }
}

@media (max-width: 360px) {
  .mobile {
    .content-container._2 {
      background-size: cover;
      background-position: top;
    }
  }
}

@media (max-width: 320px) {
  .mobile {

    .content-container {

      &._2 {
        //background: url("../images/background/main-april_mobile.png") no-repeat center/cover !important;
      }
    }
  }

  .header {
    text-align: left;
  }
}
</style>
